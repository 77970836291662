import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Today was a total curveball`}</h1>
    <p>{`Today was a total curveball.`}</p>
    <p>{`I’ll tell ya what happened in a minute.`}</p>
    <p>{`First - quick update on `}<strong parentName="p">{`the million dollar course`}</strong>{`.`}</p>
    <p>{`I decided to build a prototype of a course ( a 2-hour workshop) to test the
content and customer interest in a topic. The first one is a `}<em parentName="p">{`crash course`}</em>{` in
becoming a better storyteller. Storytelling helps with everything you do. From
sales to dating to raising money to being a leader. Hell, even Priests and
Rabbis need to be great storytellers to do their job.`}</p>
    <p>{`As all access pass members, you get to attend for free. It’s this sunday
`}<del parentName="p">{`(`}<a parentName="del" {...{
          "href": "https://click.convertkit-mail2.com/e5u980d4d2h7hmxrxvu8/e0hph7hk996q0wt8/aHR0cHM6Ly9ob3Bpbi50by9ldmVudHMvbmFpbC15b3VyLXN0b3J5"
        }}>{`RSVP here`}</a>{`)`}</del>{`.`}</p>
    <p>{`Some people said they tried to RSVP but it didn’t work due to some buggyness of
Hopin (the platform we’re using). That’s ironic, because rumor has it that Hopin
is raising money at a `}{`$`}{`2B valuation just 11 months after launch
(`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/e5u980d4d2h7hmxrxvu8/7qh7h8h0vvqwombz/aHR0cHM6Ly9zZW1pbHNoYWguY29tLzIwMjAvMTAvMTUvdGhlLWJyZWFrb3V0LXRlY2gtc3RhcnR1cC1vZi0yMDIwLw=="
      }}>{`wild`}</a>{`).`}</p>
    <p><strong parentName="p">{`OK now back to the curveball.`}</strong></p>
    <p>{`I was going about my day, when I got a phone call. I can’t share all the details
yet, but here’s what I can say:`}</p>
    <p>{`Someone I know & trust found out that their close friend has one of the largest
collectible card collections in the country - and is putting it up for sale for
personal reasons (can’t share here).`}</p>
    <p>{`We’re talking a collection that might be worth $20M, $30M, that we could scoop
up for ~`}{`$`}{`5M.`}</p>
    <p>{`Now, I don’t know jack shit about collectibles. I once conned my cousin out of a
holographic gyrados pokemon card, but that’s about it. But...when lady luck
swings by for lunch, you should pour out a glass of lemonade and ask her to stay
for a while.`}</p>
    <p>{`If you haven’t been following the trading card game - it’s having an interesting
resurgence.`}</p>
    <p>{`Gary V has been pumping up this space for the past couple years (youtube it),
and even guys like Logan Paul are getting hooked
(`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/e5u980d4d2h7hmxrxvu8/owhkhqh433pgr8sv/aHR0cHM6Ly93d3cueW91dHViZS5jb20vd2F0Y2g_dj01MDlWM1JTT3dHQQ=="
      }}>{`“I bought a `}{`$`}{`200k box of pokemon cards”`}</a>{`).
Plus I’ve been following a handful of startups building in this space
(`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/e5u980d4d2h7hmxrxvu8/z2hghnho99np3oap/aHR0cHM6Ly9yYWxseXJkLmNvbS8="
      }}>{`Rally`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/e5u980d4d2h7hmxrxvu8/p8heh9h9kkmgzzbq/aHR0cHM6Ly93d3cud2l0aG90aXMuY29tLw=="
      }}>{`Otis`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/e5u980d4d2h7hmxrxvu8/x0hph6hn88o9wet5/aHR0cHM6Ly93d3cuYXhpb3MuY29tL2FsdC1zcG9ydHMtY2FyZC1jb2xsZWN0aW5nLW1hbmFnZW1lbnQtdmFsdWUtZjAyYzg0NjktY2RiOC00YWUxLTliOTktODMzMDNkY2MxMDRiLmh0bWw="
      }}>{`Alt,`}</a>{`
etc).`}</p>
    <p>{`But tbh, I thought they were just making clickbait content, and didn’t pay too
much attention.`}</p>
    <p>{`That changed today. This is a massive collection of collectable cards -
including things like Garbage Pail Kids, Star Wars, Ren & Stimpy, etc.`}</p>
    <p>{`So instead of doing our course, I told Ben - `}<strong parentName="p">{`let’s get distracted`}</strong>{`. Let’s
chase this wild goose. It’s more interesting than the course, and when in doubt…
I do what’s most interesting to me.`}</p>
    <p>{`If this is actually legit, we could make millions on a lucky break.`}</p>
    <p>{`If it turns out to be a dud (most likely scenario) we will have learned a whole
bunch about this industry that we know nothing about today - and we’ll have a
fun story to tell.`}</p>
    <p><strong parentName="p">{`So we agreed:`}</strong></p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Let’s chase this for 1-2 days, and then reassess`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Let’s learn as much as we can by talking to some smart people who are in the
space`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Let’s share the story with the All Access Pass crew.`}</p>
      </li>
    </ol>
    <p>{`First move - draft the project
`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/e5u980d4d2h7hmxrxvu8/6qheh8hpvvzn7nco/aHR0cHM6Ly9kb2NzLmdvb2dsZS5jb20vZG9jdW1lbnQvZC8xaVpTNWdOcFR1dEdta2R1dnBfWlBocU5HVTk3RjV5ZnB3QUg3X0ZnT05HVS9lZGl0P3VzcD1zaGFyaW5n"
      }}>{`kickoff doc`}</a>{`
(~30mins). Snapshot below`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1202px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHtViLFf//EABcQAAMBAAAAAAAAAAAAAAAAABARIDH/2gAIAQEAAQUC2EP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAAAIDH/2gAIAQEABj8CKv8A/8QAGBABAQEBAQAAAAAAAAAAAAAAAQARMVH/2gAIAQEAAT8h66kBDIHwnbuxf//aAAwDAQACAAMAAAAQmw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCH/8QAHBAAAgICAwAAAAAAAAAAAAAAAREAITFRQZHw/9oACAEBAAE/ECAUi51GEWWaiAgahFgF+4jHZqCgp//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Kickoff Doc Snapshot",
          "title": "Kickoff Doc Snapshot",
          "src": "/static/e0599020b31308b3a72b97f55c26c394/8190e/kickoff-doc-snapshot.jpg",
          "srcSet": ["/static/e0599020b31308b3a72b97f55c26c394/a6407/kickoff-doc-snapshot.jpg 400w", "/static/e0599020b31308b3a72b97f55c26c394/a24e6/kickoff-doc-snapshot.jpg 800w", "/static/e0599020b31308b3a72b97f55c26c394/8190e/kickoff-doc-snapshot.jpg 1202w"],
          "sizes": "(max-width: 1202px) 100vw, 1202px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Next - I tried to get Ben connected to some smart people in the space.`}</p>
    <p>{`I tweeted this out, and got connected to some cool people, including Gary V`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdu4SD//xAAWEAEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQEAAQUCDL//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAaEAACAgMAAAAAAAAAAAAAAAAAAREhMVGh/9oACAEBAAE/IW4QXvgj/9oADAMBAAIAAwAAABAAD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQEAAwEAAAAAAAAAAAAAAAERACExQf/aAAgBAQABPxCmMFXdet9cB8yCG2uf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Collectibles Tweet",
          "title": "Collectibles Tweet",
          "src": "/static/65bceb3747e760db70b5a5c3c2743e8e/7293b/collectibles-tweet.jpg",
          "srcSet": ["/static/65bceb3747e760db70b5a5c3c2743e8e/a6407/collectibles-tweet.jpg 400w", "/static/65bceb3747e760db70b5a5c3c2743e8e/a24e6/collectibles-tweet.jpg 800w", "/static/65bceb3747e760db70b5a5c3c2743e8e/7293b/collectibles-tweet.jpg 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "998px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "87.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQCAwX/xAAXAQEBAQEAAAAAAAAAAAAAAAADAgAB/9oADAMBAAIQAxAAAAH2JrNWcaxuaEJyCl//xAAcEAACAQUBAAAAAAAAAAAAAAAAAQIDEBESISL/2gAIAQEAAQUCqcjuxSZ1mEeb6o//xAAYEQACAwAAAAAAAAAAAAAAAAABAgAQEv/aAAgBAwEBPwFlJmTf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQAQEv/aAAgBAgEBPwEQmi//xAAcEAABBAMBAAAAAAAAAAAAAAAAATEzoRARIUH/2gAIAQEABj8C4uiWiShhjzLIf//EAB4QAAEEAQUAAAAAAAAAAAAAAAEAEBEhMUFRYZHR/9oACAEBAAE/IQE4L5iWA4YlLLKNlxukBoDgHyX/2gAMAwEAAgADAAAAENc/fv/EABoRAAICAwAAAAAAAAAAAAAAAAABsdERMVH/2gAIAQMBAT8QYZTmxdpsYtH/xAAaEQACAgMAAAAAAAAAAAAAAAAAAbHRETFR/9oACAECAQE/EFmGoofCKEPZ/8QAIRABAAIABAcAAAAAAAAAAAAAAQARECFBYTFRcZGh0fD/2gAIAQEAAT8QtwQB4k2v3pFF5zm9RQZjTYGo63bSlwGwYIIiWOktgAvQT//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Gary V Twitter DM",
          "title": "Gary V Twitter DM",
          "src": "/static/ce285bc88630a045d0e5ec7b012f3a23/9ab4e/gary-vee-dm.jpg",
          "srcSet": ["/static/ce285bc88630a045d0e5ec7b012f3a23/a6407/gary-vee-dm.jpg 400w", "/static/ce285bc88630a045d0e5ec7b012f3a23/a24e6/gary-vee-dm.jpg 800w", "/static/ce285bc88630a045d0e5ec7b012f3a23/9ab4e/gary-vee-dm.jpg 998w"],
          "sizes": "(max-width: 998px) 100vw, 998px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Lastly - we drafted a list of questions we’d need to answer in order to move
forward.`}</p>
    <p><strong parentName="p">{`Things like:`}</strong></p>
    <p><strong parentName="p">{`Seller motivation:`}</strong></p>
    <ul>
      <li parentName="ul">{`Why sell?`}</li>
      <li parentName="ul">{`Why sell now?`}</li>
      <li parentName="ul">{`Why hasn’t this sold already? If it’s such a good deal`}</li>
    </ul>
    <p><strong parentName="p">{`Industry Intel:`}</strong></p>
    <ul>
      <li parentName="ul">{`How do you value these things?`}</li>
      <li parentName="ul">{`How do we know the stuff is legit?`}</li>
      <li parentName="ul">{`What happens if I flood the market with inventory after buying, do prices
crash?`}</li>
      <li parentName="ul">{`How much would it cost to diligence this (check authenticity, condition) etc.`}</li>
    </ul>
    <p><strong parentName="p">{`Business Potential:`}</strong></p>
    <ul>
      <li parentName="ul">{`How long would it take to flip?`}</li>
      <li parentName="ul">{`Who would be a potential buyer?`}</li>
      <li parentName="ul">{`Etc…`}</li>
    </ul>
    <p>{`Ben is hunting down the answers, and we setup meetings with some smart people
for tmrw to kick the tires on this.`}</p>
    <p>{`98% of the time, this will be a big ol’ pile of nothing. Just a fun story to
tell. And in the 2% chance that this is actually a great asset to buy&flip... we
will be ready to strike.`}</p>
    <p>{`Some distractions are worth the detour!`}</p>
    <p>{`Stay tuned.`}</p>
    <p>{`-`}{` Shaan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      